import { css } from '@emotion/react';
import { IconArrowNarrowRight, IconCalendar } from '@tabler/icons-react';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatDate } from '@amalia/core/types';
import {
  Button,
  DatePicker,
  FormLayout,
  Group,
  IconButton,
  Modal,
  useSnackbars,
} from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { type DateRange } from '../../../../../utils/common.types';

interface DateRangeModalProps {
  readonly value: DateRange;
  readonly onChange: (value: DateRange) => void;
  readonly disabled?: boolean;
}

export const DateRangeModal = memo(function DateRangeModal({ value, onChange, disabled }: DateRangeModalProps) {
  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();

  const { isModalOpen, setModalOpenTrue, setModalOpenFalse } = useBoolState(false, 'modalOpen');

  // We're proxifing values so you can fiddle in the dialog without modifying the real value.
  const [valueProxy, setValueProxy] = useState<DateRange>({ startDate: null, endDate: null });

  // If user submits, send proxified values to onChange.
  const onApply = useCallback(() => {
    // Checking here if startDate < endDate
    if (valueProxy.startDate && valueProxy.endDate && valueProxy.startDate.getTime() > valueProxy.endDate.getTime()) {
      snackError(<FormattedMessage defaultMessage="You can't select a start date that is after the end date" />);
      return;
    }

    setModalOpenFalse();
    onChange(valueProxy);
  }, [valueProxy, setModalOpenFalse, onChange, snackError]);

  // If user dismisses, close the popup and reset proxy.
  const onDismiss = useCallback(() => {
    setModalOpenFalse();
    // Reset proxy to initial value.
    setValueProxy(value);
  }, [setValueProxy, value, setModalOpenFalse]);

  const onChangeStart = useCallback(
    (newValue: Date | null) =>
      setValueProxy((currentValue) => ({
        ...currentValue,
        startDate: newValue,
      })),
    [],
  );

  const onChangeEnd = useCallback(
    (newValue: Date | null) =>
      setValueProxy((currentValue) => ({
        ...currentValue,
        endDate: newValue,
      })),
    [],
  );

  // When value changed in parent component, refresh the value proxy too
  useEffect(() => setValueProxy(value), [value]);

  const hasValue = !!(valueProxy.startDate || valueProxy.endDate);

  const restCommonButtonProps = {
    onClick: setModalOpenTrue,
    size: hasValue ? Button.Size.SMALL : undefined,
    disabled,
    children: hasValue ? (
      <Group
        wrap
        align="center"
        as="span"
        css={css`
          text-align: center;
          max-width: 100%;
        `}
      >
        <span>
          {valueProxy.startDate
            ? formatDate(valueProxy.startDate, 'YYYY-MM-DD')
            : formatMessage({ defaultMessage: 'N/A' })}
        </span>
        <IconArrowNarrowRight />
        <span>
          {valueProxy.endDate ? formatDate(valueProxy.endDate, 'YYYY-MM-DD') : formatMessage({ defaultMessage: 'N/A' })}
        </span>
      </Group>
    ) : (
      <IconCalendar />
    ),
  };

  const commonButtonProps = {
    ...restCommonButtonProps,
    startIcon: hasValue ? <IconCalendar /> : null,
  };

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {hasValue ? (
          <Button
            variant={Button.Variant.LIGHT}
            {...commonButtonProps}
            css={css`
              width: 100%;
            `}
          />
        ) : (
          <IconButton
            label={formatMessage({ defaultMessage: 'Set a date range' })}
            {...restCommonButtonProps}
            icon={<IconCalendar />}
            size={IconButton.Size.MEDIUM}
          />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        size={Modal.Size.LARGE}
        onClose={onDismiss}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage defaultMessage="Select a date range" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormLayout>
              <FormLayout.Group>
                <FormLayout.Row>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    disabled={disabled}
                    id="start-date"
                    label={<FormattedMessage defaultMessage="Start date" />}
                    value={valueProxy.startDate}
                    onChange={onChangeStart}
                  />

                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    disabled={disabled}
                    id="end-date"
                    label={<FormattedMessage defaultMessage="End date" />}
                    value={valueProxy.endDate}
                    onChange={onChangeEnd}
                  />
                </FormLayout.Row>
              </FormLayout.Group>
            </FormLayout>
          </Modal.Body>
        </Modal.Content>

        <Modal.Actions>
          <Modal.CancelAction />

          <Modal.MainAction
            disabled={disabled}
            onClick={onApply}
          >
            <FormattedMessage defaultMessage="Save" />
          </Modal.MainAction>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
});
