import { css } from '@emotion/react';
import { isNaN, isNil, uniqBy } from 'lodash';
import moment from 'moment';
import { Fragment, memo, type ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AmaliaFunction, CalculationParser, SanitizeFormula } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { type VariableFormatOptionsTable } from '@amalia/amalia-lang/tokens/types';
import {
  type DatasetRow,
  formatTotal,
  getFieldAsOption,
  type Overwrite,
  type Statement,
  type StatementDataset,
  TracingTypes,
} from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Typography } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { amaliaThemeInstance } from '@amalia/ext/mui/theme';
import { FormulaService } from '@amalia/frontend/web-data-layers';
import { type Ability, ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { log } from '@amalia/kernel/logger/client';
import { type CurrencyValue, isCurrencyValue } from '@amalia/kernel/monetary/types';
import { Tooltip } from '@amalia/lib-ui';
import { TracingBlock, TracingLabel, TracingLink, TracingTable } from '@amalia/lib-ui-business';
import {
  type ComputedRule,
  type Dataset,
  DatasetType,
  type FilterDataset,
  type RelationDataset,
} from '@amalia/payout-calculation/types';
import { type ComputedPlanRuleFieldsToDisplay, HidableElementVisibility } from '@amalia/payout-definition/plans/types';

import { RowsTable, type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../detail/Rule/RowsTable/RowsTable';

const FunctionsEnum = AmaliaFunction.getFunctionsEnum();

// We store, for each function, the number of nodes to retrieve. Every node counts, even commas or closing parenthesis
const numberOfParametersAccordingToFunction: Record<string, number> = {
  // 1 param
  [FunctionsEnum.isNull]: 2,
  // 2 params separated by a comma
  [FunctionsEnum.SUM]: 4,
  // 2 params separated by a comma
  [FunctionsEnum.LINEAR]: 4,
  // 2 params separated by a comma
  [FunctionsEnum.TIER]: 4,
  // 2 params separated by a comma
  [FunctionsEnum.MARGINAL]: 4,
  // 3 params separated by a comma
  [FunctionsEnum.IF]: 6,
  // 3 params separated by a comma
  [FunctionsEnum.convertCurrency]: 6,
};
// Get parameter nodes for function. Will explore further nodes according to the function type
export const getFunctionParameters = (
  functionName: typeof FunctionsEnum,
  currentIndex: number,
  formulaNodes: any[],
) => {
  // Verifying if current index is in range and of the function type
  if (formulaNodes[currentIndex]?.name !== functionName) return [];

  // Retreieve number of parameters depending on the function type
  const currentNumberOfParameters: number = numberOfParametersAccordingToFunction[formulaNodes[currentIndex]?.name];

  // If the function is unrecognized, return empty array
  if (!currentNumberOfParameters) return [];

  // If the formula nodes do not enough nodes to satisfy number of parameters, return empty array
  if (formulaNodes.length <= currentIndex + currentNumberOfParameters) return [];

  // Return 'currentNumberOfParameters' following nodes
  return formulaNodes.slice(currentIndex + 1, currentIndex + currentNumberOfParameters + 1);
};

// Prints a filter with a rowsTable
export const renderFilter = (
  ability: Ability,
  dataset: FilterDataset,
  statement: Statement,
  statementDatasets: Record<string, StatementDataset>,
  computedRule: ComputedRule,
  classes: any,
  setTracingData?: (data: TracingTypes.CurrentTracingDataType) => any,
): any => {
  const customObjectDefinition =
    statement.results.definitions.customObjects[dataset.customObjectDefinition?.machineName];
  const filterDefinition = statement.results.definitions.filters[dataset.filterMachineName];
  const planRuleDefinition = statement.results.definitions.plan.rules?.find(
    (rule) => rule.ruleMachineName === computedRule.ruleMachineName,
  );

  const filterFormula = filterDefinition.condition;

  // Build fields according to the formula
  const filterFields = new Set<{ label: string; name: string }>();
  if (filterFormula && customObjectDefinition) {
    for (const fieldName of customObjectDefinition.externalIds) {
      const field = getFieldAsOption(customObjectDefinition, fieldName);
      if (field) {
        filterFields.add(field);
      }
    }

    // Add name field by default
    const field = getFieldAsOption(customObjectDefinition, 'name');
    if (field) {
      filterFields.add(field);
    }

    let formulaToComputeFilters = filterFormula;

    if (dataset.parentIds?.includes(computedRule?.id) && planRuleDefinition?.formula) {
      formulaToComputeFilters = planRuleDefinition.formula;
    }

    if (formulaToComputeFilters) {
      FormulaService.getFormulaObjectsFields(
        formulaToComputeFilters,
        customObjectDefinition,
        statement.results,
        Array.from(filterFields),
      ).forEach((item) => {
        filterFields.add(item);
      });
    }
  }

  // Something somewhere overwrites the dataset id to replace it with the filterId,
  // so we're recomputing the id based on definition, which is something that should never
  // be done. Tracing v2 should solve this, hopefully.
  const datasetId = `f:${dataset.customObjectDefinition.machineName}:${dataset.filterMachineName}`;

  if (!statementDatasets[datasetId]) {
    return <FormattedMessage defaultMessage="Cannot trace this item" />;
  }

  // Render a rowsTable with these fields
  return (
    <TracingBlock
      key={`FILTER_${filterDefinition?.id}`}
      formula={filterFormula}
      statementCurrency={statement.currency}
      type={TracingTypes.TracingBlockType.FILTER}
    >
      {filterFields ? (
        <div className={classes.tracingInnerTable}>
          <RowsTable
            hideComments
            hideEditOverwrite
            dataset={dataset}
            datasetRows={statementDatasets[datasetId].rows}
            // @ts-expect-error -- I have no idea what I'm doing.
            fields={Array.from(filterFields.values())}
            isReadOnly={ability.cannot(ActionsEnum.view, SubjectsEnum.Data)}
            setCurrentTracingData={setTracingData}
          />
        </div>
      ) : null}
    </TracingBlock>
  );
};

export const renderRowMarginalIndex = (
  node: any,
  statement: Statement,
  statementDatasets: Record<string, StatementDataset>,
  currencySymbol: CurrencySymbolsEnum,
  classes: any,
  fields?: ComputedPlanRuleFieldsToDisplay[],
  setTracingData?: (datasetRow: DatasetRow, fields: ComputedPlanRuleFieldsToDisplay[]) => any,
  overwrite?: Overwrite,
) => {
  const rowMarginalIndexFilter: FilterDataset | RelationDataset = node.subFilter?.filter || {
    rows: [],
  };

  const customObjectDefinition =
    rowMarginalIndexFilter.customObjectDefinition?.machineName &&
    statement.results.definitions.customObjects[rowMarginalIndexFilter.customObjectDefinition.machineName];

  let formula;
  if ((rowMarginalIndexFilter as FilterDataset).filterMachineName) {
    const filterDefinition =
      statement.results.definitions.filters[(rowMarginalIndexFilter as FilterDataset).filterMachineName];
    formula = filterDefinition?.condition;
  } else if ((rowMarginalIndexFilter as RelationDataset).relationMachineName) {
    const relationDefinition =
      statement.results.definitions.relationships[(rowMarginalIndexFilter as RelationDataset).relationMachineName];
    formula = relationDefinition?.condition;
  }

  // Build fields according to the formula
  const filterFields = new Set<ComputedPlanRuleFieldsToDisplay>();
  if (formula && customObjectDefinition) {
    for (const fieldName of customObjectDefinition.externalIds) {
      const field = getFieldAsOption(customObjectDefinition, fieldName) as ComputedPlanRuleFieldsToDisplay;
      if (field) {
        filterFields.add(field);
      }
    }

    for (const fieldToMatch of fields || []) {
      const field = getFieldAsOption(customObjectDefinition, fieldToMatch.name) as ComputedPlanRuleFieldsToDisplay;

      if (field) {
        filterFields.add(field);
      }
    }

    // Add name field by default
    const field = getFieldAsOption(customObjectDefinition, 'name') as ComputedPlanRuleFieldsToDisplay;
    if (field) {
      filterFields.add(field);
    }

    FormulaService.getFormulaObjectsFields(
      formula,
      customObjectDefinition,
      statement.results,
      Array.from(filterFields),
    ).forEach((item) => {
      // For Welmo, hide the 'Montant' column by default
      if (item.name === 'montantFormatNombre') {
        filterFields.add({ ...item, displayStatus: HidableElementVisibility.AVAILABLE });
      } else {
        filterFields.add({ ...item, displayStatus: HidableElementVisibility.ON_DISPLAY });
      }
    });
  }

  (node.subFilter?.fieldsToAdd || []).forEach((fieldToShow: { name: string; label: string }) => {
    if (customObjectDefinition) {
      const field = getFieldAsOption(customObjectDefinition, fieldToShow.name);
      if (field) {
        // If a field is found, push it
        filterFields.add({ ...field, displayStatus: HidableElementVisibility.ON_DISPLAY });
      } else {
        // Otherwise take the input fieldToShow as it (may contains machineNames)
        filterFields.add({ ...fieldToShow, displayStatus: HidableElementVisibility.ON_DISPLAY });
      }
    }
  });

  const finalFilterFields: ComputedPlanRuleFieldsToDisplayWithSubTitle[] = uniqBy(
    Array.from(filterFields.values()),
    'name',
  )
    // Hiding recommandation column for welmo -- TODO: Remove that when they churn.
    .filter(
      (filterField: ComputedPlanRuleFieldsToDisplayWithSubTitle) =>
        !['recommandation', 'Recommandation', 'agent', 'Agent', 'dateAnnulation', 'dateannulation'].includes(
          filterField.name,
        ),
    )
    .map((field: ComputedPlanRuleFieldsToDisplayWithSubTitle) => ({
      ...field,
      label: statement?.results?.definitions?.variables?.[field.name]
        ? statement?.results?.definitions?.variables?.[field.name].name
        : field.label,
    }));

  let rows = [];

  // Manage cumulative field to sum values
  if (node.subFilter?.fieldToSum) {
    // Not that proud of this.
    let cumulativeFieldToSumValue =
      node.subFilter?.startAmount?.value?.total || node.subFilter?.startAmount?.value || 0.0;

    const indexOfFieldToSum = finalFilterFields.findIndex(
      (field: ComputedPlanRuleFieldsToDisplay) => field.name === node.subFilter.fieldToSum,
    );

    if (indexOfFieldToSum !== -1) {
      // Add the cumul column and the cumul with currency column just after the column to be summed
      finalFilterFields.splice(
        indexOfFieldToSum + 1,
        0,
        ...[
          {
            name: 'automaticCurrencyCumulOnRowMarginalIndex',
            label: `Σ ${finalFilterFields[indexOfFieldToSum]?.label}`,
            subTitle: cumulativeFieldToSumValue
              ? `Starting at ${formatTotal(cumulativeFieldToSumValue, FormatsEnum.currency, currencySymbol, 1)}`
              : undefined,
            displayStatus: HidableElementVisibility.ON_DISPLAY,
          },
          {
            name: 'automaticCumulOnRowMarginalIndex',
            label: `Σ ${finalFilterFields[indexOfFieldToSum]?.label}`,
            displayStatus: HidableElementVisibility.AVAILABLE,
          },
        ],
      );
    }

    // Modify filter rows to add the new cumul
    // @ts-expect-error -- Sometimes the rows are in the element and I don't know why
    rows = (rowMarginalIndexFilter.rows || statementDatasets[rowMarginalIndexFilter.id]?.rows || []).map(
      (row: DatasetRow) => {
        // If the row contains an amount, sum it into the cumulative value
        if (node.subFilter?.fieldToSum && row.content[node.subFilter.fieldToSum]) {
          // Get the value attr if this is a currency value. Otherwise, just get the value
          let valueOfThisRow = isCurrencyValue(row.content[node.subFilter?.fieldToSum])
            ? (row.content[node.subFilter?.fieldToSum] as CurrencyValue).value
            : row.content[node.subFilter?.fieldToSum];

          // If there is an overwrite and it's on this field
          const overwriteOfThisField = (row.overwrites || []).find((ov) => ov.field === node.subFilter.fieldToSum);
          if (overwriteOfThisField) {
            // Take its value instead of the row original value
            valueOfThisRow = isCurrencyValue(overwriteOfThisField.overwriteValue[node.subFilter?.fieldToSum])
              ? parseInt(overwriteOfThisField.overwriteValue[node.subFilter?.fieldToSum].value, 10)
              : parseInt(overwriteOfThisField.overwriteValue[node.subFilter?.fieldToSum], 10);
          }

          cumulativeFieldToSumValue = Math.round((cumulativeFieldToSumValue + valueOfThisRow) * 100) / 100;
        }
        return {
          ...row,
          content: {
            ...row.content,
            // Add the cumulative value as property of the row
            automaticCumulOnRowMarginalIndex: cumulativeFieldToSumValue,
            automaticCurrencyCumulOnRowMarginalIndex: formatTotal(
              cumulativeFieldToSumValue,
              FormatsEnum.currency,
              currencySymbol,
              1,
            ),
          },
        };
      },
    );
  }

  const isFilterOkToShow = rows && rows.length > 0;
  const blockValue = isFilterOkToShow ? null : overwrite?.overwriteValue?.[node.machineName] || node?.subFilter?.total;

  let tracingTable: { formula: any; formatOptions?: VariableFormatOptionsTable };

  if (node.subFilter?.tracingTable) {
    if (node.subFilter.tracingTable?.formula) {
      tracingTable = {
        formula: node.subFilter.tracingTable.formula,
        formatOptions: node.subFilter.tracingTable.formatOptions,
      };
    } else {
      tracingTable = {
        formula: node.subFilter.tracingTable,
      };
    }
  }

  // Render a rowsTable with these fields
  return (
    <TracingBlock
      key={`RENDER_MARGINAL_${node.subFilter?.filter?.definition?.id}`}
      formula={node.formula}
      machineName={node.machineName}
      overwrite={overwrite}
      statementCurrency={statement.currency}
      type={TracingTypes.TracingBlockType.ROW_MARGINAL}
      value={blockValue}
    >
      <Fragment>
        {filterFields ? (
          <div className={classes.tracingInnerTable}>
            {isFilterOkToShow ? (
              <RowsTable
                hideComments
                hideEditOverwrite
                useDefaultGetCellValue
                dataset={rowMarginalIndexFilter}
                datasetRows={rows}
                fields={finalFilterFields}
                rowsToHighlight={[node.subFilter?.parentExternalId]}
                setCurrentTracingData={setTracingData}
                sortProps={
                  node.subFilter?.fieldToSum
                    ? [{ columnName: 'automaticCumulOnRowMarginalIndex', direction: 'asc' }]
                    : []
                }
              />
            ) : overwrite?.field === node?.machineName ? (
              <FormattedMessage defaultMessage="This value was overwritten" />
            ) : (
              <FormattedMessage defaultMessage="Nothing to display" />
            )}
          </div>
        ) : null}

        {tracingTable ? (
          <TracingTable
            formatOptions={tracingTable?.formatOptions}
            formula={tracingTable?.formula}
            value={tracingTable?.formula}
          />
        ) : null}
      </Fragment>
    </TracingBlock>
  );
};

// Render a tooltip that prints a period
export const renderTooltipPeriod = (forPeriod: any, children: ReactElement<any, any>) =>
  forPeriod ? (
    <Tooltip
      title={
        <Fragment>
          <FormattedMessage defaultMessage="Calculated in period:" />
          <br />
          <FormattedMessage
            defaultMessage="Start date: {startDate}{br}End date: {endDate}"
            values={{
              startDate: moment(forPeriod.startDate, 'X').format('YYYY-MM-DD'),
              endDate: moment(forPeriod.endDate, 'X').format('YYYY-MM-DD'),
            }}
          />
        </Fragment>
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

// This function calls the MathJS parser to retrieve the nodes of the formula
export const parseFormula = (
  tracingData: any,
  computedRule: ComputedRule,
  statement: Statement,
  statementDatasets: Record<string, StatementDataset>,
  datasetRow?: DatasetRow,
  dataset?: Dataset,
): any => {
  if (!tracingData) {
    return [];
  }
  const planRuleDefinition = statement.results.definitions.plan.rules?.find(
    (rule) => rule.ruleMachineName === computedRule.ruleMachineName,
  );
  const planRuleDataset: FilterDataset | undefined = planRuleDefinition
    ? (statement.results.datasets.find(
        (ds) =>
          ds.type === DatasetType.filter &&
          (ds as FilterDataset).filterMachineName === planRuleDefinition.ruleFilterMachineName,
      ) as FilterDataset)
    : undefined;
  const planRuleDatasetDefinition = planRuleDataset
    ? statement.results.definitions.filters[planRuleDataset.filterMachineName]
    : undefined;

  // First, sanitize the formula to speak the "MathJS Language"
  const sanitizedFormula = SanitizeFormula.amaliaFormulaToMathJs(tracingData.formula);

  if (!sanitizedFormula) {
    log.error(`Formula can't be parsed: ${tracingData.formula}`);
    return [];
  }

  // Then retrieve the nodes
  const formulaNodes = FormulaService.getFormulaNodes(
    sanitizedFormula,
    computedRule,
    statement,
    statementDatasets,
    datasetRow,
    dataset,
  );

  // To finish, manage the case where the formula of a rule needs to be overriden
  // In this case, if the rule has a filter, we need to sum all lines of the filter with the formula
  // So, the "formula" becomes "SUM(filter, formula)"
  if (
    tracingData.type === TracingTypes.FormulaNodeType.rule &&
    planRuleDefinition?.ruleFilterMachineName &&
    planRuleDatasetDefinition
  ) {
    // Add the sum and rule filter to the formula
    formulaNodes.unshift(
      {
        value: {
          ...computedRule,
          result: computedRule,
          function: FunctionsEnum.SUM,
        },
        type: TracingTypes.FormulaNodeType.function,
        name: FunctionsEnum.SUM,
      },
      { value: '(', type: TracingTypes.FormulaNodeType.operator },
      {
        value: {
          ...planRuleDataset,
          ...planRuleDatasetDefinition,
          formula: planRuleDatasetDefinition.condition,
          type: TracingTypes.FormulaNodeType.filter_dataset,
        },
        type: TracingTypes.FormulaNodeType.filter_dataset,
      },
      { value: ',', type: TracingTypes.FormulaNodeType.operator },
    );
    // Add the SUM closins parenthesis at the end
    formulaNodes.push({ value: ')', type: 'operator' });
  }

  // Return the parsed nodes
  return formulaNodes;
};

export const showColouredIfWithCondition = (formulaNodes: any[], index: number) => {
  const currentIfNode = formulaNodes[index];

  let bgColor = 'transparent';
  // We need to get all nodes starting from this node.
  // We're adding +2 to remove the current node (IF) and its corresponding opening parenthesis
  const nodesStartingFromThisIf = [...formulaNodes];
  nodesStartingFromThisIf.splice(0, index + 2);

  // Then we remove all nodes after the first encountered comma
  const nodesForFirstIfParameter = nodesStartingFromThisIf.slice(
    0,
    nodesStartingFromThisIf.findIndex((n) => n.type === TracingTypes.FormulaNodeType.operator && n.value === ',') || 0,
  );

  // We analyze those nodes. If one is a function or a parenthesis, we can't do anything
  const isFormulaComputable = nodesForFirstIfParameter.every(
    (n) => n.type !== TracingTypes.FormulaNodeType.function && n.value !== ')',
  );

  if (isFormulaComputable) {
    try {
      // We transform each node we find in the condition to represent its value
      // So we have at the end a formula that contains only numbers, operators and strings
      // At least ideally, otherwise we throw an error
      const conditionToEvaluate = nodesForFirstIfParameter
        .map((node) => {
          switch (node.type) {
            case TracingTypes.FormulaNodeType.operator:
              return node.value?.toString();
            case TracingTypes.FormulaNodeType.constant:
              return `"${node.value?.toString()}"`;
            case TracingTypes.FormulaNodeType.user:
            case TracingTypes.FormulaNodeType.statement:
              return node.value?.value?.toString();
            case TracingTypes.FormulaNodeType.custom_object:
              return `"${node.value?.total?.toString()}"`;
            default:
              throw new Error(`Cannot parse condition node ${node.type}`);
          }
        })
        .join(' ');

      // Try to compute the formula to have the result of the condition
      const calculationParser = new CalculationParser();
      const result = calculationParser.computeFormula(conditionToEvaluate as AmaliaFormula);

      // Depending on the result, compute the IF background color
      bgColor = result ? amaliaThemeInstance.palette.success.light : amaliaThemeInstance.palette.error.light;
    } catch (e) {
      // Do nothing here, we just can't parse the condition so the if will not have a color
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  return (
    <TracingLabel
      key={index}
      bgColor={bgColor}
      type={TracingTypes.TracingLabelType.function}
    >
      {currentIfNode.name}
    </TracingLabel>
  );
};

// Render a formula and its nodes.
// Made to be printed into a tracingblock. Does not render TracingBlocks!
// In terms of architecture, this function prints tracing labels: one per formula node.
export const renderFormula = (
  tracingData: any,
  indexChild: number,
  formulaChildren: any[],
  setFormulaChildren: any,
  currencySymbol: CurrencySymbolsEnum,
  currencyRate: number,
  formulaNodes: any,
): any =>
  formulaNodes.map((node: any, index: any): any => {
    if (!node || !tracingData) {
      return null;
    }

    // OTHER NODE: renders arrays and other non-parsed formulas
    if ([TracingTypes.FormulaNodeType.array, TracingTypes.FormulaNodeType.formula].includes(node.type)) {
      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.other}
        >
          {node.value}
        </TracingLabel>
      );
    }

    // Operators: just print them in user-friendly language
    if (node.type === TracingTypes.FormulaNodeType.operator) {
      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.other}
        >
          {SanitizeFormula.getPrintableOperator(node.value)}
        </TracingLabel>
      );
    }

    // CONSTANT: format a constant and prints it
    if (node.type === TracingTypes.FormulaNodeType.constant) {
      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.other}
        >
          {node.value.toString()}
        </TracingLabel>
      );
    }

    // FILTER: prints it in a special filter tracing label
    // Add a link to open the filter
    if (node.type === TracingTypes.FormulaNodeType.filter_dataset) {
      if (tracingData.forPeriod) node.value[FunctionsEnum.forPeriod] = tracingData.forPeriod;

      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.filter}
          value={renderTooltipPeriod(
            tracingData.forPeriod,
            <Typography
              variant={Typography.Variant.BODY_BASE_REGULAR}
              css={(theme) => css`
                color: ${theme.ds.colors.gray[700]};
              `}
            >
              {node.value?.rows?.length}
            </Typography>,
          )}
        >
          <TracingLink
            formulaChildren={formulaChildren}
            indexChild={indexChild}
            indexInFormula={index}
            nodePart={node}
            setFormulaChildren={setFormulaChildren}
          >
            {node.value?.label || node.value?.name}
          </TracingLink>
        </TracingLabel>
      );
    }

    // For functions, print their name in a tracing label.
    // If the function can be opened, put alink over this name to make it clickable
    // Remember to finish with another tracing label for the opening parenthesis
    if (node.type === TracingTypes.FormulaNodeType.function) {
      if (tracingData.forPeriod) node.value[FunctionsEnum.forPeriod] = tracingData.forPeriod;

      switch (node.name) {
        case FunctionsEnum.rowTiersIndex:
          return (
            <TracingLabel
              key={index}
              type={TracingTypes.TracingLabelType.function}
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            >
              rowTiers
            </TracingLabel>
          );
        case FunctionsEnum.rowMarginalIndex:
          return (
            <TracingLabel
              key={index}
              type={TracingTypes.TracingLabelType.function}
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            >
              rowMarginal
            </TracingLabel>
          );
        case FunctionsEnum.LINEAR:
        case FunctionsEnum.TIER:
        case FunctionsEnum.MARGINAL:
          return (
            <TracingLabel
              key={index}
              type={TracingTypes.TracingLabelType.function}
            >
              <TracingLink
                formulaChildren={formulaChildren}
                functionParameters={getFunctionParameters(node.name, index, formulaNodes)}
                indexChild={indexChild}
                indexInFormula={index}
                nodePart={node}
                setFormulaChildren={setFormulaChildren}
              >
                {node.name}
              </TracingLink>
            </TracingLabel>
          );
        case FunctionsEnum.IF:
          return showColouredIfWithCondition(formulaNodes, index);
        default:
          return (
            <TracingLabel
              key={index}
              type={TracingTypes.TracingLabelType.function}
            >
              {node.name}
            </TracingLabel>
          );
      }
    }

    // TABLE: put a link on the node, print it as a variable
    if (node.value?.format === FormatsEnum.table) {
      if (tracingData.forPeriod) node.value[FunctionsEnum.forPeriod] = tracingData.forPeriod;

      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.table}
        >
          <TracingLink
            formulaChildren={formulaChildren}
            indexChild={indexChild}
            indexInFormula={index}
            nodePart={node}
            setFormulaChildren={setFormulaChildren}
          >
            {node.value?.label || node.value?.name}
          </TracingLink>
        </TracingLabel>
      );
    }

    // STATEMENT, USER: print their label inside a link
    if (
      [
        TracingTypes.FormulaNodeType.statement,
        TracingTypes.FormulaNodeType.user,
        TracingTypes.FormulaNodeType.team,
        TracingTypes.FormulaNodeType.plan,
      ].includes(node.type)
    ) {
      if (tracingData.forPeriod) node.value[FunctionsEnum.forPeriod] = tracingData.forPeriod;

      let inner = node.value?.label || node.value?.name || node.value?.total || node.value;

      if (!isNil(inner) && typeof inner === 'object') {
        // eslint-disable-next-line no-console
        console.warn('Intercepted object printing', inner, 'for node: ', node);
        inner = undefined;
      }

      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.property}
          value={
            node.value?.total !== null && node.value?.total !== undefined
              ? renderTooltipPeriod(
                  tracingData.forPeriod,
                  <Typography
                    variant={Typography.Variant.BODY_BASE_REGULAR}
                    css={(theme) => css`
                      color: ${theme.ds.colors.gray[700]};
                    `}
                  >
                    {/* If the node value is a float, format it to print it. Otherwise, print it directly */}
                    {node.value && !isNaN(parseFloat(node.value?.total))
                      ? formatTotal(
                          node.value?.total || node.value?.value,
                          node.value?.format,
                          node.value?.currency || currencySymbol,
                          currencyRate,
                        )
                      : inner}
                  </Typography>,
                )
              : undefined
          }
        >
          <TracingLink
            formulaChildren={formulaChildren}
            indexChild={indexChild}
            indexInFormula={index}
            nodePart={node}
            setFormulaChildren={setFormulaChildren}
          >
            {inner}
          </TracingLink>
        </TracingLabel>
      );
    }

    // For custom object nodes, put their label surrounded by a link
    if (
      [
        TracingTypes.FormulaNodeType.custom_object,
        TracingTypes.FormulaNodeType.variable,
        TracingTypes.FormulaNodeType.rowMarginal,
      ].includes(node.type)
    ) {
      if (tracingData.forPeriod) node.value[FunctionsEnum.forPeriod] = tracingData.forPeriod;
      const inner = node.value?.label || node.value?.name;

      return (
        <TracingLabel
          key={index}
          type={TracingTypes.TracingLabelType.property}
          value={
            node.value.total
              ? renderTooltipPeriod(
                  tracingData.forPeriod,
                  <Typography
                    variant={Typography.Variant.BODY_BASE_REGULAR}
                    css={(theme) => css`
                      color: ${theme.ds.colors.gray[700]};
                    `}
                  >
                    {node.value?.total}
                  </Typography>,
                )
              : undefined
          }
        >
          {node.value?.total ? (
            <TracingLink
              formulaChildren={formulaChildren}
              indexChild={indexChild}
              indexInFormula={index}
              nodePart={node}
              setFormulaChildren={setFormulaChildren}
            >
              {inner}
            </TracingLink>
          ) : (
            inner
          )}
        </TracingLabel>
      );
    }

    // FOLLOWING: safeguards, trying to interpret what we can from the node
    if (typeof node.value === 'string') {
      return node.value;
    }
    if (typeof node === 'string') {
      return node;
    }

    // If safeguards didn't work, print an error
    log.error('Tracing / deal tracing: unrecognized node, was not able to print it', { node });

    // Then return an empty node
    return null;
  });

// ALL FOLLOWING FUNCTIONS RENDER SPECIFIC TRACING BLOCKS
interface TracingBlockForTableProps {
  readonly result: any;
  readonly statementCurrency: CurrencySymbolsEnum;
}

export const TracingBlockForTable = memo(function TracingBlockTable({
  result,
  statementCurrency,
}: TracingBlockForTableProps) {
  return (
    <TracingBlock
      key={result.variableId}
      statementCurrency={statementCurrency}
      type={TracingTypes.TracingBlockType.TABLE}
    >
      <TracingTable
        formatOptions={result.formatOptions}
        formula={result.formula}
        value={result.value}
      />
    </TracingBlock>
  );
});

interface TracingBlockForFunctionProps {
  readonly result: any;
  readonly currencySymbol: CurrencySymbolsEnum;
  readonly currencyRate: number;
  readonly functionParameters: any[];
  readonly statementCurrency: CurrencySymbolsEnum;
}

const TracingBlockForTierFunction = memo(function TracingBlockForTierFunction({
  result,
  currencySymbol,
  currencyRate,
  functionParameters,
  statementCurrency,
}: TracingBlockForFunctionProps) {
  const resultValue = useMemo(
    () => formatTotal(result.total, result.format, currencySymbol, currencyRate),
    [currencyRate, currencySymbol, result],
  );

  return (
    <TracingBlock
      key={`TIER${result.variableId}`}
      statementCurrency={statementCurrency}
      type={TracingTypes.TracingBlockType.FUNCTION}
    >
      {functionParameters ? (
        <TracingTable
          formatOptions={functionParameters[3]?.value?.formatOptions}
          formula={functionParameters[3]?.value?.formula}
          value={functionParameters[3]?.value?.value}
        />
      ) : (
        <div>{resultValue}</div>
      )}
    </TracingBlock>
  );
});

const TracingBlockForLinearFunction = memo(function TracingBlockForLinearFunction({
  result,
  currencySymbol,
  currencyRate,
  functionParameters,
  statementCurrency,
}: TracingBlockForFunctionProps) {
  const resultValue = useMemo(
    () => formatTotal(result.total, result.format, currencySymbol, currencyRate),
    [currencyRate, currencySymbol, result],
  );

  return (
    <TracingBlock
      key={`LINEAR${result.variableId}`}
      statementCurrency={statementCurrency}
      type={TracingTypes.TracingBlockType.FUNCTION}
    >
      {functionParameters ? (
        <TracingTable
          formatOptions={functionParameters[3]?.value?.formatOptions}
          formula={functionParameters[3]?.value?.formula}
          value={functionParameters[3]?.value?.value}
        />
      ) : (
        <div>{resultValue}</div>
      )}
    </TracingBlock>
  );
});

const TracingBlockForMarginalFunction = memo(function TracingBlockForMarginalFunction({
  result,
  currencySymbol,
  currencyRate,
  functionParameters,
  statementCurrency,
}: TracingBlockForFunctionProps) {
  const resultValue = useMemo(
    () => formatTotal(result.total, result.format, currencySymbol, currencyRate),
    [currencyRate, currencySymbol, result],
  );

  return (
    <TracingBlock
      key={`MARGINAL${result.variableId}`}
      statementCurrency={statementCurrency}
      type={TracingTypes.TracingBlockType.FUNCTION}
    >
      {functionParameters ? (
        <TracingTable
          formatOptions={functionParameters[3]?.value?.formatOptions}
          formula={functionParameters[3]?.value?.formula}
          value={functionParameters[3]?.value?.value}
        />
      ) : (
        <div>{resultValue}</div>
      )}
    </TracingBlock>
  );
});

// This function is called to show a specific function tracing block
export const renderFunctionTracingBlock = (
  index: number,
  tracingData: any,
  currencySymbol: CurrencySymbolsEnum,
  currencyRate: number,
  functionParameters: any[],
  statement: Statement,
) => {
  switch (tracingData.function) {
    case FunctionsEnum.TIER:
      return (
        <TracingBlockForTierFunction
          key={index}
          currencyRate={currencyRate}
          currencySymbol={currencySymbol}
          functionParameters={functionParameters}
          result={tracingData}
          statementCurrency={statement.currency}
        />
      );
    case FunctionsEnum.LINEAR:
      return (
        <TracingBlockForLinearFunction
          key={index}
          currencyRate={currencyRate}
          currencySymbol={currencySymbol}
          functionParameters={functionParameters}
          result={tracingData}
          statementCurrency={statement.currency}
        />
      );
    case FunctionsEnum.MARGINAL:
      return (
        <TracingBlockForMarginalFunction
          key={index}
          currencyRate={currencyRate}
          currencySymbol={currencySymbol}
          functionParameters={functionParameters}
          result={tracingData}
          statementCurrency={statement.currency}
        />
      );
    default:
      log.warn('Missing function definition for', tracingData);
      break;
  }
  return null;
};
