import { type FormikProps } from 'formik';
import { without } from 'lodash';
import { type ForwardedRef, forwardRef, memo, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { LanguagesEnum } from '@amalia/kernel/intl/types';
import { useCurrentCompany } from '@amalia/tenants/companies/state';
import { useSyncUsers } from '@amalia/tenants/users/profile/state';
import { UserExternalIdSource, UserHrisIdSource, UserRole } from '@amalia/tenants/users/types';

export type CreateUserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  language: LanguagesEnum;
  currency: CurrencySymbolsEnum;
  externalIdSource: Exclude<UserExternalIdSource, UserExternalIdSource.NONE> | null;
  externalId: string;
  hrisIdSource: Exclude<UserHrisIdSource, UserHrisIdSource.NONE> | null;
  hrisId: string;
};

type CreateUserFormikProps = FormikFormProps<CreateUserFormValues>;

export type CreateUserFormProps = Omit<
  CreateUserFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
>;

const CreateUserFormForwardRef = forwardRef(function CreateUserForm(
  { children, ...props }: CreateUserFormProps,
  ref: ForwardedRef<FormikProps<CreateUserFormValues>>,
) {
  const { data: company } = useCurrentCompany();
  const { mutateAsync: createUser } = useSyncUsers();

  const initialValues: CreateUserFormValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      role: UserRole.EMPLOYEE,
      language: LanguagesEnum.en,
      currency: company.currency,
      externalIdSource: null,
      externalId: '',
      hrisIdSource: null,
      hrisId: '',
    }),
    [company.currency],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        role: Yup.string().oneOf(Object.values(UserRole)).required(),
        email: Yup.string().required().email(),
        language: Yup.string().oneOf(Object.values(LanguagesEnum)).required(),
        currency: Yup.string()
          .oneOf([...company.symbols, company.currency])
          .required(),
        externalIdSource: Yup.string()
          .oneOf(without(Object.values(UserExternalIdSource), UserExternalIdSource.NONE))
          .nullable(),
        externalId: Yup.string().when('externalIdSource', {
          is: null,
          then: (schema) => schema.oneOf(['']),
          otherwise: (schema) => schema.required(),
        }),
        hrisIdSource: Yup.string()
          .oneOf(without(Object.values(UserHrisIdSource), UserHrisIdSource.NONE))
          .nullable(),
        hrisId: Yup.string().when('hrisIdSource', {
          is: null,
          then: (schema) => schema.oneOf(['']),
          otherwise: (schema) => schema.required(),
        }),
      }),
    [company.currency, company.symbols],
  );

  const handleSubmit: CreateUserFormikProps['onSubmit'] = useCallback(
    async (values) => {
      await createUser([
        {
          ...values,
          externalIdSource: values.externalIdSource === null ? UserExternalIdSource.NONE : values.externalIdSource,
          externalId: values.externalId || undefined,
          hrisIdSource: values.hrisIdSource === null ? UserHrisIdSource.NONE : values.hrisIdSource,
          hrisId: values.hrisId || undefined,
        },
      ]);
    },
    [createUser],
  );

  return (
    <FormikForm
      {...props}
      ref={ref}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});

export const CreateUserForm = memo(CreateUserFormForwardRef);
