import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { stubFalse } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { type UnknownRecord } from 'type-fest';
import { type ObjectSchema } from 'yup';

import { CONNECTORS } from '@amalia/core/types';
import {
  type DataConnectorTypes,
  type DataConnectorObject,
  type PatchDataConnectorRequest,
} from '@amalia/data-capture/connectors/types';
import { useSnackbars } from '@amalia/design-system/components';
import { objectToQs, openInNewTab, qsToObject } from '@amalia/ext/web';
import { config } from '@amalia/kernel/config/client';

import { DataConnectorObjectApiClient } from '../api-client/data-connector-object.api-client';
import { DataConnectorsApiClient } from '../api-client/data-connectors.api-client';

import { DATA_CONNECTOR_MUTATION_KEYS, DATA_CONNECTOR_QUERY_KEYS } from './queries.keys';

const FIVE_MINUTES = 1000 * 60 * 5;

export const useDataConnectors = () =>
  useQuery({
    queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS],
    queryFn: DataConnectorsApiClient.list,
  });

export const useDataConnector = (connectorType?: DataConnectorTypes | null) => {
  const { data, ...rest } = useDataConnectors();

  return {
    ...rest,
    data: data?.find((connector) => connector.type === connectorType),
  };
};

/**
 * React query mutation hook to add a data connector.
 */
export const useCreateConnector = ({
  shouldIgnoreError = stubFalse,
}: {
  shouldIgnoreError?: (error: Error) => boolean;
} = {}) => {
  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataConnectorsApiClient.create,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },

    onError: (err, { type }) => {
      if (!shouldIgnoreError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Could not connect to {connectorType}: {errorMessage}.' },
            { connectorType: CONNECTORS[type].name, errorMessage: err.message },
          ),
        );
      }
    },
  });
};

export const useUpdateConnectorAuth = ({
  shouldIgnoreError = stubFalse,
}: {
  shouldIgnoreError?: (error: Error) => boolean;
} = {}) => {
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataConnectorsApiClient.updateConnectorAuth,
    onSuccess: async (_, { type }) => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });

      snackSuccess(
        formatMessage(
          { defaultMessage: 'Authentication to {connectorType} updated successfully.' },
          { connectorType: CONNECTORS[type].name },
        ),
      );
    },
    onError: (err, { type }) => {
      if (!shouldIgnoreError(err)) {
        snackError(
          formatMessage(
            { defaultMessage: 'Could not update authentication to {connectorType}: {errorMessage}.' },
            { connectorType: CONNECTORS[type].name, errorMessage: err.message },
          ),
        );
      }
    },
  });
};

export const useListObjectFields = (dataConnectorId: string, objectName: string) =>
  useQuery({
    staleTime: FIVE_MINUTES,
    queryKey: [DATA_CONNECTOR_QUERY_KEYS.OBJECT_FIELDS, dataConnectorId, objectName],
    queryFn: async () => DataConnectorsApiClient.listObjectFields(dataConnectorId, objectName),
    enabled: !!dataConnectorId && !!objectName,
  });

export const useListObject = (dataConnectorId: string) =>
  useQuery({
    staleTime: FIVE_MINUTES,
    queryKey: [DATA_CONNECTOR_QUERY_KEYS.OBJECTS, dataConnectorId],
    queryFn: async () => DataConnectorsApiClient.listObjects(dataConnectorId),
    enabled: !!dataConnectorId,
  });

type PatchConnectorObjectMutationInput = {
  connectorId: string;
  connectorObject: PatchDataConnectorRequest;
};

export const usePatchDataConnector = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ connectorId, connectorObject }: PatchConnectorObjectMutationInput) =>
      DataConnectorsApiClient.patch(connectorId, connectorObject),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

type DeleteConnectorObjectMutationInput = {
  objectName: string;
  dataConnectorId: string;
};

/**
 * React query mutation hook to delete a data connector object.
 */
export const useDeleteObjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: DeleteConnectorObjectMutationInput) =>
      DataConnectorObjectApiClient.deleteConnectorObject(variables.dataConnectorId, variables.objectName),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

type UpdateConnectorObjectMutationInput = {
  dataConnectorObject: DataConnectorObject;
  dataConnectorId: string;
};

/**
 * React query mutation hook to add a data connector object.
 */
export const useAddConnectorObjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: UpdateConnectorObjectMutationInput) =>
      DataConnectorObjectApiClient.addConnectorObject(variables.dataConnectorId, variables.dataConnectorObject),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

/**
 * React query mutation hook to update a data connector object.
 */
export const useUpdateDataConnectorObjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [DATA_CONNECTOR_MUTATION_KEYS.UPDATE_DATA_CONNECTOR_OBJECT],
    mutationFn: (variables: UpdateConnectorObjectMutationInput) =>
      DataConnectorObjectApiClient.updateConnectorObject(variables.dataConnectorId, variables.dataConnectorObject),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
    },
  });
};

/**
 * React query mutation hook to log out from a data connector object.
 */
export const useConnectorLogout = () => {
  const { snackError, snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DataConnectorsApiClient.logout,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DATA_CONNECTOR_QUERY_KEYS.CONNECTORS] });
      snackSuccess(formatMessage({ defaultMessage: 'Successfully logged out from connector.' }));
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'Could not log out from connector: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const useAuthenticateWithOAuth = <TSchema extends UnknownRecord>(validationSchema: ObjectSchema<TSchema>) => {
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(
    () => () => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    },
    [abortController],
  );

  return useMutation({
    /**
     * Authenticate to OAuth in a new window and return the parsed query string of the callback route (aka the OAuth payload needed for authorize() in the connectors).
     */
    mutationFn: ({
      connectorType,
      queryParams,
    }: {
      connectorType: DataConnectorTypes;
      /** Query params to pass to /login route. */
      queryParams?: Record<string, string>;
    }): Promise<TSchema> =>
      new Promise<TSchema>((resolve, reject) => {
        const broadcastChannel = new BroadcastChannel('connectors-oauth-callback');

        const queryString = objectToQs(queryParams || {});

        const authWindowWidth = 800;
        const authWindowHeight = 600;

        // Open a new window to /login (will redirect the new window to the OAuth service authorization url).
        openInNewTab(
          `${config.api.url}/connectors/${encodeURIComponent(connectorType)}/login?${queryString}`,
          [
            `width=${authWindowWidth}`,
            `height=${authWindowHeight}`,
            // This centers the window on the screen.
            `left=${Math.floor(window.screenX + (window.outerWidth - authWindowWidth) / 2)}`,
            `top=${Math.floor(window.screenY + (window.outerHeight - authWindowHeight) / 2)}`,
            // These options open the url in a new window instead of a new tab.
            // I copied from https://medium.com/front-end-weekly/use-github-oauth-as-your-sso-seamlessly-with-react-3e2e3b358fa1
            'toolbar=0',
            'scrollbars=1',
            'status=1',
            'resizable=1',
            'location=1',
            'menuBar=0',
          ].join(','),
        );

        const onMessage = (event: MessageEvent) => {
          try {
            resolve(validationSchema.validateSync(qsToObject((event.data as { payload: string }).payload)) as TSchema);
          } catch (error) {
            reject(error);
          } finally {
            broadcastChannel.removeEventListener('message', onMessage);
            broadcastChannel.close();
            abortController.signal.removeEventListener('abort', onAbort);
          }
        };

        // Since we're waiting indefinitely for the authentication to end, we need to provide a way to abort it.
        // When the component is unmounted, we abort the authentication process.
        const onAbort = () => {
          broadcastChannel.removeEventListener('message', onMessage);
          broadcastChannel.close();
          abortController.signal.removeEventListener('abort', onAbort);

          reject(new Error('Authentication aborted'));
        };

        // Wait for the authentication process to end.
        broadcastChannel.addEventListener('message', onMessage);
        abortController.signal.addEventListener('abort', onAbort);
      }),

    onError: (err) => {
      snackError(
        formatMessage({ defaultMessage: 'Authentication failed: {errorMessage}.' }, { errorMessage: err.message }),
      );
    },
  });
};
