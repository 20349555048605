import { css } from '@emotion/react';
import { memo, useMemo } from 'react';

import { type VariableFormatOptionsTable } from '@amalia/amalia-lang/tokens/types';
import { type Statement } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Stack, Typography, useSnackbars } from '@amalia/design-system/components';
import { extractChartConfiguration, type ExtractedChartConfiguration } from '@amalia/frontend/web-data-layers';
import { type CardinalChartRowWithNumberOnly } from '@amalia/lib-ui';
import { type PlanRuleChart } from '@amalia/payout-definition/plans/types';

import { TargetAchievementChart } from './TargetAchievementChart/TargetAchievementChart';

interface ChartItemProps {
  readonly chart: PlanRuleChart;
  readonly statement: Statement;
  readonly hideName?: boolean;
}

export const ChartItem = memo(function ChartItem({ chart, statement, hideName }: ChartItemProps) {
  const { snackError } = useSnackbars();

  const extractedChartConfiguration: ExtractedChartConfiguration | null = useMemo(() => {
    const result = extractChartConfiguration(statement, chart);
    if (result.error) {
      snackError(result.error);
      return null;
    }
    return result;
  }, [chart, statement, snackError]);

  const labelsToAdd = useMemo(() => {
    const result: CardinalChartRowWithNumberOnly[] = [];

    if (extractedChartConfiguration?.values?.table?.variable?.name) {
      result.push({
        x: 0,
        value: 0,
        valueLabel: extractedChartConfiguration.values.table.variable.name,
      });
    }

    if (extractedChartConfiguration?.values?.target?.variable?.name) {
      result.push({
        x: 325,
        value: 415,
        valueLabel: extractedChartConfiguration.values.target.variable.name,
      });
    }

    return result;
  }, [extractedChartConfiguration]);

  const [xAxisFormat, yAxisFormat]: ['number' | 'percent', 'number' | 'percent'] = useMemo(() => {
    const tableFormat = (
      extractedChartConfiguration?.values?.table.variable.formatOptions as VariableFormatOptionsTable
    )?.columns?.[2]?.format;
    return [
      extractedChartConfiguration?.values?.target.variable.format === FormatsEnum.percent ? 'percent' : 'number',
      tableFormat === FormatsEnum.percent ? 'percent' : 'number',
    ];
  }, [extractedChartConfiguration]);

  if (!extractedChartConfiguration?.values) {
    return null;
  }

  return (
    <Stack
      gap={16}
      justify="center"
      css={css`
        padding: 0 24px;
      `}
    >
      {!hideName && !!chart.name && (
        <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>{chart.name}</Typography>
      )}

      <TargetAchievementChart
        labelsToAdd={labelsToAdd}
        mode={extractedChartConfiguration.values.mode}
        table={extractedChartConfiguration.values.table.value}
        value={extractedChartConfiguration.values.target.value}
        xAxisFormat={xAxisFormat}
        yAxisFormat={yAxisFormat}
      />
    </Stack>
  );
});
