import { css } from '@emotion/react';
import { IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import { Form, Formik } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Button, FormikSelect, FormLayout, Modal } from '@amalia/design-system/components';
import {
  type CreateHighlightedKpiRequest,
  type HighlightedKpi,
  type HighlightedKpiIdentifier,
} from '@amalia/payout-definition/plans/types';

export type HighlightedKpiModalProps = {
  readonly currentValue?: HighlightedKpi;
  readonly selectedIdentifier: HighlightedKpiIdentifier | null;
  readonly onSubmit: (
    kpi: CreateHighlightedKpiRequest & { identifier: HighlightedKpiIdentifier },
  ) => Promise<void> | void;
  readonly onCancel: () => void;
  readonly onRemove: (selectedIdentifier: HighlightedKpiIdentifier) => void;
  readonly availableVariables: Variable[];
};

const validationSchema = Yup.object().shape({
  maximumVariableId: Yup.string(),
  minimumVariableId: Yup.string(),
  variableId: Yup.string().required(),
});

const getVariableGroupOptions = (variables: Variable[], variableType: VariableType) =>
  variables
    .filter((variable) => variable.type === variableType)
    .map((variable) => ({
      value: variable.id,
      label: variable.name,
    }));

export const HighlightedKpiModal = memo(function HighlightedKpiModal({
  currentValue,
  selectedIdentifier,
  onSubmit,
  onRemove,
  onCancel,
  availableVariables,
}: HighlightedKpiModalProps) {
  const initialValues = useMemo(
    () => ({
      maximumVariableId: currentValue?.maximumVariableId || '',
      minimumVariableId: currentValue?.minimumVariableId || '',
      variableId: currentValue?.variableId || '',
    }),
    [currentValue],
  );

  const onRemoveProxy = useCallback(() => {
    // It's defined because the modal is open.
    onRemove(selectedIdentifier);
  }, [onRemove, selectedIdentifier]);

  // Add identifier to the result.
  const onSubmitProxy = useCallback(
    (values: Omit<HighlightedKpi, 'identifier'>) =>
      onSubmit({
        identifier: selectedIdentifier,
        variableId: values.variableId,
        minimumVariableId: values.minimumVariableId || null,
        maximumVariableId: values.maximumVariableId || null,
      }),
    [onSubmit, selectedIdentifier],
  );

  const usableVariables = useMemo(
    () =>
      availableVariables.filter((v) =>
        [FormatsEnum.percent, FormatsEnum.currency, FormatsEnum.number].includes(v.format),
      ),
    [availableVariables],
  );

  const variableOptions = useMemo(
    () => [
      {
        label: <FormattedMessage defaultMessage="Plan variables" />,
        initialIsOpen: false,
        options: getVariableGroupOptions(usableVariables, VariableType.plan),
      },
      {
        label: <FormattedMessage defaultMessage="Team variables" />,
        initialIsOpen: false,
        options: getVariableGroupOptions(usableVariables, VariableType.team),
      },
      {
        label: <FormattedMessage defaultMessage="User variables" />,
        initialIsOpen: false,
        options: getVariableGroupOptions(usableVariables, VariableType.user),
      },
      {
        label: <FormattedMessage defaultMessage="Statement" />,
        initialIsOpen: false,
        options: getVariableGroupOptions(usableVariables, VariableType.statement),
      },
    ],
    [usableVariables],
  );

  return (
    <Modal
      isOpen={!!selectedIdentifier}
      onClose={onCancel}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitProxy}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Set highlighted KPI" />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormLayout>
                  <FormLayout.Group>
                    <FormikSelect
                      required
                      id="variableId"
                      label={<FormattedMessage defaultMessage="Variable" />}
                      name="variableId"
                      options={variableOptions}
                    />

                    <FormikSelect
                      id="minimumVariableId"
                      label={<FormattedMessage defaultMessage="Minimum" />}
                      name="minimumVariableId"
                      options={variableOptions}
                    />

                    <FormikSelect
                      id="maximumVariableId"
                      label={<FormattedMessage defaultMessage="Maximum" />}
                      name="maximumVariableId"
                      options={variableOptions}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Body>
            </Modal.Content>
            <Modal.Actions>
              <Modal.CancelAction />

              <Button
                icon={<IconTrash />}
                variant={Button.Variant.DANGER}
                onClick={onRemoveProxy}
              >
                <FormattedMessage defaultMessage="Remove" />
              </Button>

              <Modal.MainAction
                disabled={!isValid || !dirty}
                icon={<IconDeviceFloppy />}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Save" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
});
