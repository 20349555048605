import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconDots, IconVariable } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, useMemo, useContext, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatToIcon } from '@amalia/data-capture/fields/components';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import {
  Group,
  MenuDropdown,
  type TablerIconElement,
  type SelectDropdownOption,
  SelectDropdown,
  IconButton,
} from '@amalia/design-system/components';
import { type AmaliaThemeType } from '@amalia/ext/mui/theme';

import { TableBuilderContext } from '../TableBuilderContext';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  cell: {
    padding: `0 ${theme.spacing(2.5)}`,
    backgroundColor: theme.palette.common.white,
    // In readonly mode, action buttons disappear and the cell are really tight.
    height: 50,

    '&:not(:last-child)': {
      borderRight: `2px solid ${theme.palette.divider}`,
    },
  },
  cellHeader: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.light,
  },
  actionCell: {
    '&:first-child': {
      padding: 0,
    },
    '&:not(:last-child)': {
      borderRight: 0,
    },
  },
}));

type SupportedFormats =
  | FormatsEnum.boolean
  | FormatsEnum.currency
  | FormatsEnum.date
  | FormatsEnum.number
  | FormatsEnum.percent
  | FormatsEnum.table
  | FormatsEnum.text;

type FormatDropdownOption = SelectDropdownOption<SupportedFormats | 'formula'> & {
  icon: TablerIconElement;
};

export const TableBuilderCellHeader = memo(function TableBuilderCellHeader(props: TableHeaderRow.CellProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const FORMAT_OPTIONS = useMemo(
    () =>
      (
        [
          FormatsEnum.percent,
          FormatsEnum.currency,
          FormatsEnum.number,
          FormatsEnum.date,
          FormatsEnum.text,
          FormatsEnum.table,
          FormatsEnum.boolean,
          'formula',
        ] as const
      ).map(
        (formatOrFormula): FormatDropdownOption => ({
          value: formatOrFormula,
          label: formatOrFormula,
          icon: formatOrFormula === 'formula' ? <IconVariable /> : formatToIcon[formatOrFormula],
        }),
      ),

    [],
  );

  const { column } = props;
  const { onAddColumn, onDeleteColumn, onChangeColumnFormat, onRenameColumn, nbColumns, isReadonly } =
    useContext(TableBuilderContext);

  const columnName = column.name || 'defaultName';

  const onAddColumnLeft = useCallback(
    () =>
      // Here diff is actually 0 because the new column will take the place of this one.
      onAddColumn(column.name, 0),
    [onAddColumn, column],
  );

  const onAddColumnRight = useCallback(() => onAddColumn(column.name, 1), [onAddColumn, column]);

  const onDeleteColumnProxy = useCallback(() => onDeleteColumn(column.name), [onDeleteColumn, column]);

  const onChangeFormat = useCallback(
    (value: SupportedFormats | 'formula') => onChangeColumnFormat(column.name, value as FormatsEnum),
    [onChangeColumnFormat, column],
  );

  const onRenameColumnProxy = useCallback(() => onRenameColumn(column.name), [onRenameColumn, column]);

  if (props.column.name === 'actions') {
    return (
      <TableHeaderRow.Cell
        {...props}
        className={clsx(classes.cell, classes.cellHeader, classes.actionCell)}
      />
    );
  }

  // @ts-expect-error -- Too bad DX Grid doesn't let us add values here, but we'll do it anyway.
  const format = column.format as SupportedFormats | 'formula';

  return (
    <TableHeaderRow.Cell
      {...props}
      className={clsx(classes.cell, classes.cellHeader)}
    >
      <Group
        align="center"
        justify="space-between"
        css={css`
          width: 100%;
        `}
      >
        {!isReadonly && (
          <SelectDropdown<FormatDropdownOption>
            id={`column_format_${columnName}`}
            options={FORMAT_OPTIONS}
            value={format}
            onChange={onChangeFormat}
          >
            {({ value, isDropdownOpen }) => (
              <IconButton
                data-testid={`column_format_${columnName}`}
                icon={value!.icon}
                id={`column_format_${columnName}`}
                isActive={isDropdownOpen}
                label={formatMessage({ defaultMessage: 'Select format' })}
              />
            )}
          </SelectDropdown>
        )}

        <span>{column.title}</span>
        {!isReadonly && (
          <MenuDropdown
            id={`column_actions_${columnName}`}
            content={
              <div>
                <MenuDropdown.Item
                  data-testid={`insert-column-right-${columnName}`}
                  label={<FormattedMessage defaultMessage="Insert column right" />}
                  onClick={onAddColumnRight}
                />

                <MenuDropdown.Item
                  data-testid={`insert-column-left-${columnName}`}
                  label={<FormattedMessage defaultMessage="Insert column left" />}
                  onClick={onAddColumnLeft}
                />

                <MenuDropdown.Item
                  data-testid={`rename-column-${columnName}`}
                  label={<FormattedMessage defaultMessage="Rename column" />}
                  onClick={onRenameColumnProxy}
                />

                {nbColumns > 2 && (
                  <MenuDropdown.Item
                    data-testid={`delete-column-${columnName}`}
                    label={<FormattedMessage defaultMessage="Delete column" />}
                    onClick={onDeleteColumnProxy}
                  />
                )}
              </div>
            }
          >
            <MenuDropdown.IconButton
              icon={<IconDots />}
              label={formatMessage({ defaultMessage: 'Actions on column {columnName}' }, { columnName })}
            />
          </MenuDropdown>
        )}
      </Group>
    </TableHeaderRow.Cell>
  );
});
