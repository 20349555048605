import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';

import { RulesApiClient } from './rules.api-client';
import { RULES_MUTATION_KEYS, RULES_QUERY_KEYS } from './rules.keys';

export const useRules = ({ enabled }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: [RULES_QUERY_KEYS.RULES, 'list'],
    queryFn: () => RulesApiClient.list(),
    enabled,
  });

export const useCreateRule = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [RULES_MUTATION_KEYS.RULES, 'create'],
    mutationFn: (...args: Parameters<typeof RulesApiClient.create>) => RulesApiClient.create(...args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [RULES_QUERY_KEYS.RULES, 'list'] });
    },
  });
};

export const useUpdateRule = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { snackSuccess, snackError } = useSnackbars();

  return useMutation({
    mutationKey: [RULES_MUTATION_KEYS.RULES, 'update'],
    mutationFn: (...args: Parameters<typeof RulesApiClient.update>) => RulesApiClient.update(...args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [RULES_QUERY_KEYS.RULES, 'list'] });
      snackSuccess('Rule updated.');
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while updating the rule: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const useDeleteRule = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [RULES_MUTATION_KEYS.RULES, 'delete'],
    mutationFn: (...args: Parameters<typeof RulesApiClient.delete>) => RulesApiClient.delete(...args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [RULES_QUERY_KEYS.RULES, 'list'] });
    },
  });
};
