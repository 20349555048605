import { isArrayNode } from 'mathjs';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { type VariableFormatOptionsColumn, type VariableFormatOptionsTable } from '@amalia/amalia-lang/tokens/types';
import { StringUtils } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';
import { log } from '@amalia/kernel/logger/client';

import { TableBuilder } from './TableBuilder';
import { type TableBuilderRow, type TableBuilderColumn } from './tableBuilder.types';

interface TableBuilderFieldProps {
  readonly formula: string;
  readonly formatOptions: VariableFormatOptionsTable | undefined;
  readonly setFieldValue: (
    field: 'formatOptions' | 'formula',
    value: VariableFormatOptionsTable | string | null,
  ) => Promise<unknown>;
}

export const TableBuilderField = memo(function TableBuilderField({
  formula,
  formatOptions,
  setFieldValue,
}: TableBuilderFieldProps) {
  const { snackError } = useSnackbars();

  const setColumns = useCallback(
    (columns: TableBuilderColumn[]) => {
      setFieldValue('formatOptions', {
        ...(formatOptions || {}),
        columns: columns as VariableFormatOptionsColumn[],
      }).catch(log.error);
    },
    [formatOptions, setFieldValue],
  );

  const setRows = useCallback(
    (rows: TableBuilderRow[]) => {
      const newRows = rows.map((row) => `[${(row || []).join(', ')}]`);
      const newFormula = `[${newRows.join(', ')}]`;

      try {
        commonMathJs.parse(newFormula);
        setFieldValue('formula', newFormula).catch(log.error);
      } catch (e) {
        snackError(
          <FormattedMessage
            defaultMessage="Invalid value: {error}"
            values={{ error: toError(e).message }}
          />,
        );
      }
    },
    [setFieldValue, snackError],
  );

  const rows = useMemo(() => {
    try {
      const parsedFormula = commonMathJs.parse(formula);

      if (!isArrayNode(parsedFormula)) {
        return [];
      }

      return parsedFormula.items
        .map((row) => {
          if (!isArrayNode(row)) {
            return false;
          }

          return row.items.map((item) => item.toString());
        })
        .filter(Boolean);
    } catch (e) {
      log.error(e, { formula });
      snackError(<FormattedMessage defaultMessage="Formula can't be parsed as a table. We reset the table for you." />);
      return [[0]];
    }
  }, [formula, snackError]);

  const columns = useMemo(() => {
    let parsedColumns = formatOptions?.columns;

    // If no column retrieved, intialize columns array
    if (!parsedColumns) {
      parsedColumns = [];
    }

    let newColumnsCount = 1;

    // If there's more data than columns, push new columns
    while (rows[0]?.length > parsedColumns.length) {
      const newName = `Column ${newColumnsCount}`;
      parsedColumns.push({
        name: newName,
        machineName: StringUtils.camelCase(newName),
        format: FormatsEnum.number,
      });
      newColumnsCount += 1;
    }

    // If there's less data than columns, delete the extra columns
    if (rows[0]?.length < parsedColumns.length) {
      parsedColumns = parsedColumns.splice(0, rows[0].length);
    }

    return parsedColumns;
  }, [formatOptions, rows]);

  return (
    <TableBuilder
      columns={columns}
      rows={rows}
      setColumns={setColumns}
      setRows={setRows}
    />
  );
});
