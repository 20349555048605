import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import { type VariablesMap, type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';
import { useSnackbars } from '@amalia/design-system/components';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { PLAN_QUERY_KEYS } from '../queries.keys';

import { VariablesApiClient } from './variables.api-client';
import { VARIABLE_QUERY_KEYS } from './variables.keys';

export const useFieldsListByObjectId = (customDefinitionObjectId: string) =>
  useQuery({
    queryKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'listByCustomObjectId', customDefinitionObjectId],
    queryFn: () => VariablesApiClient.getByObjectId(customDefinitionObjectId),
    placeholderData: [],
    enabled: !!customDefinitionObjectId,
  });

export const useVariables = (filters?: { types?: VariableType[]; userIds?: string[]; planIds?: string[] }) =>
  useQuery({
    queryKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'list', filters],
    queryFn: () => VariablesApiClient.list(filters?.types, filters?.userIds, filters?.planIds),
  });

export const useVariablesMap = (...args: Parameters<typeof useVariables>) => {
  const { data, ...rest } = useVariables(...args);
  return { data: data ? (keyBy(data, 'id') satisfies VariablesMap as VariablesMap) : undefined, ...rest };
};

export const useDuplicateVariablesInNewContext = () => {
  const queryClient = useQueryClient();
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: (args: Parameters<typeof VariablesApiClient.duplicateInNewContext>) =>
      VariablesApiClient.duplicateInNewContext(...args),

    onSuccess: async ({ isErrorWhileDuplicating }) => {
      if (isErrorWhileDuplicating) {
        snackError(
          formatMessage({
            defaultMessage: 'Error while importing variables. Variables with the same name already exist.',
          }),
        );
      }

      await queryClient.invalidateQueries({ queryKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'list'] });
    },
  });
};

const patchVariableSuccessMessages = defineMessages<VariableType>({
  [VariableType.object]: { defaultMessage: 'Field updated' },
  [VariableType.statement]: { defaultMessage: 'Statement variable updated' },
  [VariableType.user]: { defaultMessage: 'User quota definition updated' },
  [VariableType.team]: { defaultMessage: 'Team quota definition updated' },
  [VariableType.plan]: { defaultMessage: 'Plan quota definition updated' },
});

export const usePatchVariable = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'patch'],
    mutationFn: VariablesApiClient.patch,
    onSuccess: async (updatedVariable) => {
      await queryClient.invalidateQueries({ queryKey: [VARIABLE_QUERY_KEYS.VARIABLE] });
      snackSuccess(formatMessage(patchVariableSuccessMessages[updatedVariable.type]));
    },
    onError: (error) => {
      snackError(formatMessage({ defaultMessage: `Error while updating: {message}` }, { message: error.message }));
    },
  });
};

export const useDeleteVariable = ({ planId }: { planId: Plan['id'] }) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [VARIABLE_QUERY_KEYS.VARIABLE, 'delete'],
    mutationFn: (variableId: Variable['id']) => VariablesApiClient.delete(variableId, planId),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Variable deleted successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.RULE_V2, 'configuration', planId] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occurred while deleting the variable: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};
